export const generateInitialValues = ({ fields }) => {
	let initialValues = {};

	for (let field of fields) {
		switch (field.type) {
			case "checkbox":
			case "multiselect":
				initialValues[field.name] = [];
				break;
			case "upload":
				initialValues[field.name] = {};
				break;
			case "number":
				initialValues[field.name] = "";
				break;

			case "radio":
				initialValues[field.name] = field.initialValues || "";
				break;

			case "schedule":
				initialValues[field.name] = {};
				for (let fieldName of field.fieldNames) {
					initialValues[field.name][fieldName] = "";
				}
				break;

			case "select":
				initialValues[field.name] = {
					label: "",
					value: "",
				};
				break;

			case "date":
				initialValues[field.name] = {
					month: {
						label: "",
						value: "",
					},
					date: {
						label: "",
						value: "",
					},
					year: "",
				};
				break;

			case "address":
				let addressInitialValues = {};
				if (field.initialValues) {
					addressInitialValues = JSON.parse(field.initialValues);
				}
				initialValues[field.name] = {
					city: addressInitialValues.city || {
						label: "",
						value: "",
					},
					province: addressInitialValues.province || {
						label: "",
						value: "",
					},
					siteName: addressInitialValues.siteName || {
						label: "",
						value: "",
					},
					streetAddress: addressInitialValues.streetAddress || "",
					addressType: addressInitialValues.addressType || "",
					notes: "",
				};
				break;

			case "search":
				initialValues[field.name] = [];
				break;

			default:
				initialValues[field.name] = "";
				break;
		}
	}

	return initialValues;
};
