import React, { useReducer } from "react";

import { NutritionistReducer } from "./reducer";
import { useAirtableNutritionistFormFields } from "./hooks/useAirtableNutritionistFormFields";
import { generateInitialValues } from "../../../services/context";

const NutritionistContext = React.createContext();

const NutritionistProvider = ({ children }) => {
	let formFields = useAirtableNutritionistFormFields();

	let [nutritionistState, nutritionistDispatch] = useReducer(
		NutritionistReducer,
		{
			...generateInitialValues({ fields: formFields }),
			documents: [],
			toast: {
				isActive: false,
				message: null,
				color: null,
			},
		}
	);

	return (
		<NutritionistContext.Provider
			value={{ nutritionistState, nutritionistDispatch }}
		>
			{children}
		</NutritionistContext.Provider>
	);
};

export { NutritionistContext, NutritionistProvider };
