import { useStaticQuery, graphql } from "gatsby";

import { flattenNode } from "../../../../services/arrays";

export const useAirtableNutritionistFormFields = () => {
	let formFields = useStaticQuery(graphql`
		query AllAirtableNutritionFormFields {
			allAirtableallNutritionFormFields(
				sort: { fields: data___Order }
				filter: {
					data: { Show_On_Website: { eq: true }, Program: { in: "MGX1SOLMH" } }
				}
			) {
				nodes {
					data {
						Type
						Required
						Name
						Field_Names
						Helper
						Follow_Up_Questions
						Follow_Up_Questions_Name
						Is_Follow_Up_Question
						Label
						Module
						Options
						Order
						Reference_Answer
						Reference_Question
						Reference_Question_Name
						Section
						Section_Order
						Show_On_Website
						Summary_Section
						Summary_Label
						Validation
					}
				}
			}
		}
	`);

	return flattenNode(formFields?.allAirtableallNutritionFormFields);
};
