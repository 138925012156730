import React from "react";
import { ApolloProvider } from "react-apollo";
import { client } from "./src/services/apollo";

import { AppProvider } from "./src/context/AppContext";
import { ConsultationProvider } from "./src/components/Consultations/ConsultationContext/ConsultationContext";
import { MedEnsureProvider } from "./src/components/MedEnsure/MedEnsureContext/MedEnsureContext";
import { WellnessSurveyProvider } from "./src/components/WellnessSurvey/context/WellnessSurveyContext";
import { NutritionistProvider } from "./src/components/Nutritionist/context";

import { getFirebase } from "services/firebase/firebase";
import "./static/styles/global.scss";

export const wrapRootElement = ({ element }) => {
	getFirebase();

	return (
		<AppProvider>
			<ConsultationProvider>
				<NutritionistProvider>
					<MedEnsureProvider>
						<WellnessSurveyProvider>
							<ApolloProvider client={client}>{element}</ApolloProvider>
						</WellnessSurveyProvider>
					</MedEnsureProvider>
				</NutritionistProvider>
			</ConsultationProvider>
		</AppProvider>
	);
};
